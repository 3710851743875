import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';
import { map, Observable, skipWhile } from 'rxjs';
import { Logger } from '@shared';
import { AppRoute } from 'src/generated/extended';
import { BillingService } from '@app/core/services/billing/billing/billing.service';
import { LicenseStateDTO, WithdrawalProductType } from '@generated/generatedEntities';
import { INSUFFICIENT_ROUTES } from '@app/insufficient_rights/insufficient-rights-routing.module';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

const log = new Logger('HasLicenseGuard');

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class HasLicenseGuard {
  private router = inject(Router);
  private _licenseState = inject(BillingService);

  constructor() {
    const parent = inject(HasLicenseGuard, { optional: true, skipSelf: true });

    // log.error('init');
    if (parent) {
      throw Error(
        `[GuardedSingletonService]: trying to create multiple instances,
        but this service should be a singleton.`,
      );
    }
  }

  resolve(
    route: ActivatedRouteSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const routerData = route as AppRoute;
    return this.hasLicenseGuard(routerData);
  }

  private hasLicenseGuard(route: AppRoute) {
    return this._licenseState.licenseState().pipe(
      untilDestroyed(this),
      skipWhile((value: any) => value == null),
      map((state: LicenseStateDTO) => {
        if (state.licenseState === 'INVALID') {
          const router = route as ActivatedRouteSnapshot;
          const path = router.routeConfig?.path;
          const getModules = router.data['module'] ? router.data['module'] : router.data['modules'];
          const module = this.setModules(getModules);
          this.onNavigate(path, 'process-form', module);
          this.onNavigate(path, 'operational-form', module);
          this.onNavigate(path, 'lcc-form', module);
          this.onNavigate(path, 'lca-form', module);
          this.onNavigate(path, 'effort-planner-form', module);
          this.onNavigate(path, 'gvbs', module);
          this.onNavigate(path, 'damage-form', module);
          this.onNavigate(path, 'insurance-form', module);
          this.onNavigate(path, 'agv', module);
          this.onNavigate(path, 'planning-foundation-form', module);
          return false;
        }
        return true;
      }),
    );
  }

  private setModules(module: WithdrawalProductType | WithdrawalProductType[]) {
    return Array.isArray(module) ? module : [module];
  }

  private onNavigate(path: string | undefined, route: string, module: WithdrawalProductType | WithdrawalProductType[]) {
    if (path?.includes(route)) {
      this.router.navigate([INSUFFICIENT_ROUTES.LICENSE], { state: { data: { module: module } } });
    }
  }
}
