<div class="flex-1" [ngClass]="language" style="min-height: 100vh">
  <app-header [sidenav]="sidenav"></app-header>
  <mat-sidenav-container>
    <mat-sidenav #sidenav mode="over" ngClass.gt-sm="has-border"> </mat-sidenav>
    <mat-sidenav-content>
      <div class="main-container">
        <div class="content-container">
          <router-outlet></router-outlet>
        </div>
        <div class="footer-container">
          <app-footer></app-footer>
        </div>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
